import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'

function prepareParams(config) {
    return new Promise((resolve, reject) => {
        var field = config.sort

        if (config.sortDirection === 'desc') {
            field = '-' + field
        }

        var params = {
            page: config.page,
            include: config.include,
            sort: field,
            per_page: config.per_page
        }

        if(config.search)
	        params.search = config.search

        if (config.filters) {
            for (const [key, value] of Object.entries(config.filters)) {
                params[`filter[${key}]`] = value
            }
        }

        resolve(params)
    })
}

export default {
    getMyNeWPostsDashboard({ rootState, commit, dispatch }, userid) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(
                        `${rootState.baseURL}/posts/dashboard?per_page=5&filter[with_operator]=${userid}`
                    )
                    .then((response) => {
                        dispatch(
                            'computePostsAnswers',
                            response.data.posts
                        ).then((posts) => {
                            commit('setPostsTotal', response.data.total)
                            commit('setPostsDashboard', posts)

                            resolve(response.data)
                        })
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    getPostsDashboard({ rootState, commit, dispatch }) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(`${rootState.baseURL}/posts/dashboard?per_page=5`)
                    .then((response) => {
                        dispatch(
                            'computePostsAnswers',
                            response.data.posts
                        ).then((posts) => {
                            commit('setPostsTotal', response.data.total)
                            commit('setPostsDashboard', posts)

                            resolve(response.data)
                        })
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    getPostsList({ rootState, commit, dispatch }) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(
                        `${rootState.baseURL}/posts/dashboard?per_page=20&sort=-id`
                    )
                    .then((response) => {
                        dispatch(
                            'computePostsAnswers',
                            response.data.posts
                        ).then((posts) => {
                            commit('setPostsList', posts)
                            commit('setPostsTotal', response.data.total)

                            resolve(posts)
                        })
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    getPosts({ state, rootState, commit }, search = '') {
        return new Promise((resolve, reject) => {
            try {
                prepareParams(state.postsConfig).then((params) => {
                    if (search) params.search = search
                    axios
                        .get(`${rootState.baseURL}/posts`, {
                            params
                        })
                        .then((response) => {
                            commit('setPosts', response.data.posts)
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    getPostsLocation({ state, rootState, commit, dispatch }, filters) {
        return new Promise((resolve, reject) => {
            try {
                prepareParams(filters || state.myPostsConfig).then((params) => {
                    axios
                        .get(`${rootState.baseURL}/posts`, {
                            params
                        })
                        .then((response) => {
                            dispatch(
                                'computePostsAnswers',
                                response.data.posts
                            ).then((posts) => {
                                commit('setMyPosts', posts)
                                resolve(response)
                            })
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            } catch (error) {
                reject(error)
            }
        })
    },

    getExportPosts({ state, rootState }, filters) {
        return new Promise((resolve, reject) => {
            try {
                prepareParams(filters || state.postsConfig).then((params) => {
                    axios
                        .get(`${rootState.baseURL}/posts/exports`, {
                            params
                        })
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            } catch (error) {
                reject(error)
            }
        })
    },

    exportPdfPost({ rootState, state }, content) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${rootState.baseURL}/posts/${state.post.id}/export/pdf`,
                    content
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getPost({ state, rootState, commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/posts/${id}`)
                .then((response) => {
                    dispatch('computePostAnswers', response.data.post).then(
                        (post) => {
                            commit('setPost', post)
                            commit('setStatuses', response.data.statuses)
                            commit('setHistory', response.data.history)
                            resolve(response)
                        }
                    )
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sendNewComment({ state, rootState, commit }, message) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/posts/${state.post.id}/comments`, {
                    message
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sendNewMessage({ state, rootState, commit }, content) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/posts/${state.post.id}/messages`, {
                    content
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateStatus({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${rootState.baseURL}/posts/${data.id}/statuses/${data.status_id}`
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getPostsFilters({ state, rootState, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/posts/filters`)
                .then((response) => {
                    commit('setPostsFilters', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    /**
     * Set new computed property to a set of post according to their answers
     * @param context
     * @param posts
     * @returns {Promise<unknown>}
     */
    computePostsAnswers(context, posts) {
        return new Promise((resolve, reject) => {
            try {
                posts.data.forEach((post) => {
                    post.answers.forEach((answer) => {
                        switch (answer.name) {
                            case 'location':
                                post.location = answer.value
                                break
                        }
                    })
                })

                resolve(posts)
            } catch (error) {
                reject(error)
            }
        })
    },
    /**
     * Set new computed property to a set of post according to their answers
     * @param context
     * @param post
     * @returns {Promise<unknown>}
     */
    computePostAnswers(context, post) {
        return new Promise((resolve, reject) => {
            try {
                post.answers.forEach((answer) => {
                    switch (answer.name) {
                        case 'location':
                            post.location = answer.value
                            break
                    }
                })

                resolve(post)
            } catch (error) {
                reject(error)
            }
        })
    },
    searchUsersList({ rootState, commit }, search) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/users/find`, { params: { search } })
                .then((response) => {
                    commit('setUsersList', response.data.users)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getPostHistories({ rootState, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/posts/${id}/histories`)
                .then((response) => {
                    commit('setPostHistories', response.data.histories)
                    commit('setHistory', response.data.histories)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    saveUserAnswer({ commit }, attribute) {
        return new Promise((resolve, reject) => {
            try {
                commit('addAttribute', attribute)
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
    sendPost({ rootState, state }, formId) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/posts`, {
                    post: state.post,
                    form_id: formId,
                    locale: rootState.lang
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updatePost({ rootState, state }, formId) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${rootState.baseURL}/posts/${state.post.id}`, {
                    post: state.post,
                    form_id: formId,
                    locale: rootState.lang
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updatePostRacis({ rootState }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/posts/${data.id}/racis`, data.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getVolumetry({ rootState }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/stats/volumetry`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getPerformance({ rootState }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/stats/performance`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updatePriority({ rootState, state }, data) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${rootState.baseURL}/posts/${data.id}/priority/${data.priority}`
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    remindOperator({ rootState }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/posts/${id}/remind`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
